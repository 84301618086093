.wrapper{
    display: flex;
    flex-direction: row;
    width:80%;
    margin:3% auto;
    height:auto;
}
.header{
    font-size: 5rem;
    font-family: "Karantina";
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:3% auto;
    direction: rtl;
}
.row{
    display: flex;
    flex-direction: row;
}
.right{
    display: flex;
    flex-direction: column;
    flex: 4; /* Adjusted flex property to make it twice the size of the image */
    justify-content: center; /* Aligned content to the right */
    margin-left: auto;
line-height: 1.4;
}

.title, .job{
    font-family: "Karantina";
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
}
.title{
    font-size: 5rem;
  
}
.job{
    font-size: 3.5rem;
 
}
.description{
    font-size: 2.2rem;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
    width:75%;
    margin-right: auto;
    direction: rtl;
    font-family: "KarantinaL";
    letter-spacing: 1px;
}
.left{
    display: flex;
    flex: 2; /* Adjusted flex property */
}

.imageWrapper {
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bold{
    color:goldenrod;
    display: inline;
}
.image{
    width: 100%; /* Adjusted width to fill the container */
    object-fit: cover;
    height: 100%;
}
.center{
    width:50% ;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.icon{
    width:100%;
}

@media only screen  and (max-width: 350px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:90%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.2;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 2.5rem;
      
    }

    .description{
        font-size: 1.8rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:80%;
        margin: 0 auto;
    }

}

@media only screen  and (min-width: 350px) and (max-width: 450px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:90%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.2rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.2;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 2.7rem;
      
    }
 
    .description{
        font-size: 1.8rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:70%;
        margin: 0 auto;
    }

}
@media only screen  and (min-width: 450px) and (max-width: 550px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:90%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.5rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.1;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 2.9rem;
      
    }
 
    .description{
        font-size: 1.8rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:55%;
        margin: 0 auto;
    }

}
@media only screen  and (min-width: 550px) and (max-width: 750px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:85%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.1;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 3.2rem;
      
    }
 
    .description{
        font-size: 2rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:40%;
        margin: 0 auto;
    }

}
@media only screen  and (min-width: 750px) and (max-width: 850px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:80%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.2rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.1;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 3.5rem;
      
    }
 
    .description{
        font-size: 2.1rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:30%;
        margin: 0 auto;
    }

}

@media only screen  and (min-width: 850px) and (max-width: 1050px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:80%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.2rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.1;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 3.5rem;
      
    }
 
    .description{
        font-size: 2.1rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:100%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:30%;
        margin: 0 auto;
    }

}

@media only screen  and (min-width: 1050px) and (max-width: 1250px){

    .wrapper{
        display: flex;
        flex-direction: column;
        width:80%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.5rem;
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
        justify-content: center; /* Aligned content to the right */
        margin:2% auto;
    line-height: 1.1;
    }
    
    .title{
        font-family: "Karantina";
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
    }
    .title{
        font-size: 3.6rem;
      
    }
 
    .description{
        font-size: 2.1rem;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align:center;
        width:80%;
       margin: 0 auto;
        direction: rtl;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
      
    }
    
    .imageWrapper {
        width: 100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .icon{
        width:25%;
        margin: 0 auto;
    }

}