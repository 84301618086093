
.question{
    font-family: "KarantinaL"; 
    font-size: 2.5rem;
text-align: center;
margin: 2% auto;
direction: rtl;
}
.questionFixed{
    font-family: "KarantinaL"; 
    font-size: 2.5rem;
text-align: center;
margin: 2% auto;
direction: rtl;
padding-top:100px;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media only screen  and (max-width: 350px){

    .question{
        font-family: "KarantinaL"; 
        font-size: 2rem;
        width:90%;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .questionFixed{
        font-family: "KarantinaL"; 
        font-size: 2rem;
    text-align: center;
    margin: 2% auto;
    width:90%;
    direction: rtl;
    padding-top:80px;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}
@media only screen and (min-width: 350px)  and (max-width: 450px) {

    .question{
        font-family: "KarantinaL"; 
        font-size: 2.2rem;
        width:90%;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .questionFixed{
        font-family: "KarantinaL"; 
        font-size: 2.2rem;
    text-align: center;
    margin: 2% auto;
    width:90%;
    direction: rtl;
    padding-top:80px;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}
@media only screen and (min-width: 450px)  and (max-width: 650px) {

    .question{
        font-family: "KarantinaL"; 
        font-size: 2.4rem;
        width:90%;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .questionFixed{
        font-family: "KarantinaL"; 
        font-size: 2.4rem;
    text-align: center;
    margin: 2% auto;
    width:90%;
    direction: rtl;
    padding-top:80px;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}