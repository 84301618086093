.title {
    font-family: "Karantina";
    font-size: 4rem;
    color: black;
    width:100%;
    margin: 2% auto;
    text-align: center;
  }
  .center{
    display: flex;
    justify-content: center;
    margin:2% auto;
  }
  .description{
    font-family: "KarantinaL";
    font-size: 2rem;
    color: black;
    width:90%;
    margin: 2% auto;
    text-align: center;

  }
  @media only screen and (max-width: 350px) {


    .title {
        font-family: "Karantina";
        font-size: 2.5rem;
        color: black;
        width:75%;
        margin: 5% auto;
        text-align: center;
      }

  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {


    .title {
        font-family: "Karantina";
        font-size: 3rem;
        color: black;
        width:75%;
        margin: 5% auto;
        text-align: center;
      }

  }
  @media only screen  and (min-width: 450px) and (max-width: 550px) {


    .title {
        font-family: "Karantina";
        font-size: 3.2rem;
        color: black;
        width:75%;
        margin: 5% auto;
        text-align: center;
      }

  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {


    .title {
        font-family: "Karantina";
        font-size: 3.5rem;
        color: black;
        width:75%;
        margin: 5% auto;
        text-align: center;
      }

  }