.wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.bold{
    color:goldenrod;
    display: inline;
}
.center{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
width:35%;


margin:0 auto;
}
.header{
    
    font-family: "Karantina"; 
    font-size: 6rem;
text-align: center;
margin: 2% auto;
direction: rtl;
}
.left,.right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex:3
}
.specialty{
    display: flex;
    flex-direction: column;
    width:85%;
    margin:0 auto;
    padding:0.5rem;
    border-bottom: 3px white dashed;
}
.title{

    font-family: "Karantina"; 
    font-size: 3.5rem;
text-align: center;
margin: 2% auto;
direction: rtl;
}
.description{
    font-family: "KarantinaL"; 
    font-size: 2.1rem;
text-align: center;

margin: 2% auto;
direction: rtl;
}

.image{
    width:70%;
    object-fit: contain;
    display: flex;
    margin: 0 auto;
}

@media only screen  and (max-width: 350px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: space-evenly;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    .header{
        
        font-family: "Karantina"; 
        font-size: 3.5rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:80%;
    
    
    margin:4% auto 0;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:80%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 2.3rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:1.6rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:80%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }

}

@media only screen  and (min-width: 350px) and (max-width: 450px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:80%;
    
    
    margin:4% auto 0;
    }
    .header{
        
        font-family: "Karantina"; 
        font-size: 3.5rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:80%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 2.5rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:1.8rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:80%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }
}

@media only screen  and (min-width: 450px) and (max-width: 550px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:80%;
    
    
    margin:4% auto 0;
    }
    .header{
        
        font-family: "Karantina"; 
        font-size: 3.7rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:70%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 2.7rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:1.8rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:70%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }

}
@media only screen  and (min-width: 550px) and (max-width: 650px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:80%;
    margin:4% auto 0;
    }
    .header{
        
        font-family: "Karantina"; 
        font-size: 4rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:60%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 3rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:2rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:65%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }

}
@media only screen  and (min-width: 650px) and (max-width: 850px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:70%;
    margin:4% auto 0;
    }

    .header{
        
        font-family: "Karantina"; 
        font-size: 4.5rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:50%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 3.2rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:2.1rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:60%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }

}
@media only screen  and (min-width: 850px) and (max-width: 1050px){

    .wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:70%;
    margin:4% auto 0;
    }

    .header{
        
        font-family: "Karantina"; 
        font-size: 4.8rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:40%;
        margin:0 auto;
        padding:1rem;
        border-bottom: 3px white dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 3.5rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size:2.2rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:50%;
        object-fit: contain;
        display: flex;
        margin: 4% auto;
    }

}
@media only screen  and (min-width: 1050px) and (max-width: 1250px){


    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .bold{
        color:goldenrod;
        display: inline;
    }
    .center{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    width:35%;
    
    
    margin:0 auto;
    }
    .header{
        
        font-family: "Karantina"; 
        font-size: 6rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .left,.right{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
  
    }
    .specialty{
        display: flex;
        flex-direction: column;
        width:85%;
        margin:0 auto;
        padding:0.5rem;
        border-bottom: 3px rgb(255, 255, 255) dashed;
    }
    .title{
    
        font-family: "Karantina"; 
        font-size: 3rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    .description{
        font-family: "KarantinaL"; 
        font-size: 1.8rem;
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    }
    
    .image{
        width:70%;
        object-fit: contain;
        display: flex;
        margin: 0 auto;
    }
}