.wrapper{
    display: flex;
    flex-direction: row;
    width:70%;
    margin:3% auto;
    height:auto;

 
}

.header{
    font-size: 5rem;
    font-family: "Karantina";
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:3% auto;
    direction: rtl;
}
.row{
    display: flex;
    flex-direction: row;
}
.right{
    display: flex;
    flex-direction: column;
    flex: 4; /* Adjusted flex property to make it twice the size of the image */
    justify-content: center; /* Aligned content to the right */
    margin-left: auto;
line-height: 1.4;
z-index: -1;
}

.title, .job{
    font-family: "Karantina";
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
}
.title{
    font-size: 5rem;
  
}
.job{
    font-size: 3.5rem;
 
}
.description{
    font-size: 2.2rem;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
    width:75%;
    margin-right: auto;
    direction: rtl;
    font-family: "KarantinaL";
    letter-spacing: 1px;
}
.left{
    display: flex;
    z-index: -1;
    flex: 2; /* Adjusted flex property */
}

.imageWrapper {
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bold{
    color:goldenrod;
    display: inline;
    z-index: -1;
}
.image{
    width: 100%; /* Adjusted width to fill the container */
    object-fit: cover;
    height: 100%;
}
.center{
    width:50% ;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.icon{
    width:100%;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@media only screen  and (max-width: 350px){


    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    
    .header{
        font-size: 3rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2.5rem;
      
    }
    .job{
        font-size: 1.8rem;
     
    }
    .description{
        font-size: 1.3rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:95%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 60%;
        height:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0 auto;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 5px 1px rgb(0, 0, 0);
  
    }

    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
@media only screen   and (min-width: 350px) and (max-width: 450px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .header{
        font-size: 3.5rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3rem;
      
    }
    .job{
        font-size: 2rem;
     
    }
    .description{
        font-size: 1.4rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:95%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:75%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
  
    }


}
@media only screen   and (min-width: 450px) and (max-width: 550px){

    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2.8rem;
      
    }
    .job{
        font-size: 2.2rem;
     
    }
    .description{
        font-size: 1.5rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:95%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:80%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
  
    }


}
@media only screen   and (min-width: 550px) and (max-width: 650px){
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3.5rem;
      
    }
    .job{
        font-size: 2.5rem;
     
    }
    .description{
        font-size: 1.8rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:90%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:80%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
  
    }
}
@media only screen   and (min-width: 650px) and (max-width: 850px){


    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.2rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3.8rem;
      
    }
    .job{
        font-size: 2.8rem;
     
    }
    .description{
        font-size: 1.9rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:90%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:80%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
  
    }

}
@media only screen   and (min-width: 850px) and (max-width: 1050px){


    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.2rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3.8rem;
      
    }
    .job{
        font-size: 2.8rem;
     
    }
    .description{
        font-size: 1.9rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:90%;
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:85%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 1050px) and (max-width: 1250px){

    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.5rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 4rem;
      
    }
    .job{
        font-size: 3rem;
     
    }
    .description{
        font-size: 2rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:70%;
     
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:75%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
}

@media only screen   and (min-width: 1250px) and (max-width: 1450px){

    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
   
    .wrapper{
        display: flex;
        flex-direction: row;
        width:85%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4.5rem;
        font-family: "Karantina";
        color: black;
        text-shadow:2px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: center; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.2;
    }
    
    .title, .job{
        font-family: "Karantina";
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 4rem;
      
    }
    .job{
        font-size: 3rem;
     
    }
    .description{
        font-size: 2rem;
        color: black;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        width:70%;
     
        font-family: "KarantinaL";
        letter-spacing: 1px;
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
        align-items: center;
    }
    
    .imageWrapper {
        width: 100%;
        height:65%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }
}