
@import url("./fonts/font.css");
body{
  /* background: linear-gradient(35deg, rgba(255,255,255,1) 0%, rgba(255, 196, 0, 0.211) 10%, rgba(255,255,255,1) 20%, rgba(255, 196, 0, 0.211) 30%, rgba(255,255,255,1) 40%, rgba(255, 196, 0, 0.216) 50%, rgba(255,255,255,1) 60%, rgba(255, 196, 0, 0.227) 70%, rgba(255,255,255,1) 80%, rgba(255, 187, 0, 0.184) 90%, rgba(255,255,255,1) 100%); */
overflow-x:auto;
/* background: linear-gradient(135deg, rgba(255,248,220,1) 0%, rgba(255, 255, 255, 0.255) 25%, rgba(255,248,220,1) 50%, rgba(255, 255, 255, 0.252) 75%, rgba(255,248,220,1) 100%); */
background: linear-gradient(135deg, rgba(255,246,187,0.4) 0%, rgba(107,59,0,0.5) 10%, rgba(255,246,187,0.4) 20%, rgba(107,59,0,0.5) 30%, rgba(255,246,187,0.4) 40%, rgba(107,59,0,0.5) 50%, rgba(255,246,187,0.4) 60%, rgba(107,59,0,0.5) 70%, rgba(255,246,187,0.4) 80%, rgba(107,59,0,0.5) 90%, rgba(255,246,187,0.4) 100%);

}
.wrap{
  height: auto;
}
